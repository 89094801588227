<template>
    <section class="project-expertise">
        <Nav
            class="mt-5 mb-4"
            route-name="TechnologyTransfer"
            parent-page-name="Трансфер технологий"
            current-page-name="Экспертиза проектов"
        />

        <div class="project-expertise__main-img d-none d-xl-flex">
            <div class="project-expertise__blurred-img mt-lg-auto ms-lg-auto d-flex align-items-center ms-lg-4">
                <h2 class="project-expertise__title mt-auto">{{ getProjectsExpertisePageInfo('title') }}</h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="d-block ms-auto mt-auto mb-3 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="project-expertise__title project-expertise__title--dark">
                {{ getProjectsExpertisePageInfo('title') }}
            </h2>
        </div>

        <div class="project-expertise-tabs d-flex flex-column flex-md-row align-items-center py-4">
            <div class="project-expertise-tabs__item">
                <router-link :to="{ name: 'MarketingFinancial' }">
                    {{ getTabTitle('first_tab') }}
                </router-link>
            </div>
            <div class="project-expertise-tabs__item ms-md-4">
                <router-link :to="{ name: 'ScientificTechnical' }">
                    {{ getTabTitle('second_tab') }}
                </router-link>
            </div>
            <div class="project-expertise-tabs__item ms-md-4">
                <router-link :to="{ name: 'IntellectualProperty' }">
                    {{ getTabTitle('third_tab') }}
                </router-link>
            </div>
            <div class="project-expertise-tabs__item ms-md-4">
                <router-link :to="{ name: 'Legal' }">
                    {{ getTabTitle('fourth_tab') }}
                </router-link>
            </div>
        </div>

        <router-view v-slot="{ Component }">
            <transition>
                <component
                    :is="Component"
                    class="my-5"
                />
            </transition>
        </router-view>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "ProjectExpertise",

    components: {
        Nav
    },

    setup() {
        const store = useStore()
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getProjectsExpertisePage')
        const projectsExpertisePage = computed(() => store.getters['pages/projectsExpertisePage'])

        const getProjectsExpertisePageInfo = (key) => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value[key]
            }

            return ''
        }

        const getTabTitle = (key) => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget[key].tab_title
            }

            return ''
        }

        return {
            getTabTitle,
            getProjectsExpertisePageInfo,
        }
    }
}
</script>
